export default [
    {text: 'TlGridTemplate', compPath: 'grid-template/index', code: 'TlGridTemplate'},
    {text: 'DragResizeBox', compPath: 'drag-box/index', code: 'DragResizeBox'},
    {text: 'Overlap', compPath: 'demo/overlap', code: 'Overlap'},
    {text: 'Cherry Down', compPath: 'demo/cherry-down', code: 'CherryDown'},
    {text: 'Grid Layout', compPath: 'demo/grid-layout', code: 'GridLayout'},
    {text: 'My Window', compPath: 'demo/my-window', code: 'MyWindow'},
    {text: 'Slider', compPath: 'demo/slider', code: 'Slider'},
    {text: 'Photo Wall', compPath: 'demo/photo-wall', code: 'PhotoWall'},
    {text: 'Rotate', compPath: 'demo/rotate', code: 'Rotate'},
    {text: 'Player Guider', compPath: 'demo/player-guider', code: 'PlayerGuider'},
    {text: 'Three JS', compPath: 'three/index', code: 'ThreeJS'},
    // {text: 'Test Drag', compPath: 'test-drag/index', code: 'TestDrag'},
    {text: 'Draw Board', compPath: 'canvas/DrawBoard', code: 'DrawBoard'},
    // {text: 'webgl test_01', compPath: 'webgl/test_01', code: 'webgl_test_01'},
    // {text: 'webgl test_02', compPath: 'webgl/test_02', code: 'webgl_test_02'},
    // {text: 'webgl test_03', compPath: 'webgl/test_03', code: 'webgl_test_03'},
    // {text: 'webgl test_04', compPath: 'webgl/test_04', code: 'webgl_test_04'},
    // {text: 'webgl test_05', compPath: 'webgl/test_05', code: 'webgl_test_05'},
    {text: 'webgl test_06', compPath: 'webgl/test_06', code: 'webgl_test_06'},
    {text: 'webgl test_07', compPath: 'webgl/test_07', code: 'webgl_test_07'},
    {text: 'webgl test_08', compPath: 'webgl/test_08', code: 'webgl_test_08'},
    {text: 'webgl test_09', compPath: 'webgl/test_09', code: 'webgl_test_09'},
    {text: 'webgl test_10', compPath: 'webgl/test_10', code: 'webgl_test_10'},
    {text: 'webgl test_11', compPath: 'webgl/test_11', code: 'webgl_test_11'},
    {text: 'webgl test_12', compPath: 'webgl/test_12', code: 'webgl_test_12'},
    {text: 'webgl test_13', compPath: 'webgl/test_13', code: 'webgl_test_13'},
    {text: 'webgl test_14', compPath: 'webgl/test_14', code: 'webgl_test_14'},
    {text: 'virual_scroll', compPath: 'virual-scroll/index', code: 'virual_scroll'},
];
